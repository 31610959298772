import Swiper, { EffectFade, Navigation } from 'swiper';

export function homeVideoSlider() {
    const $s = $('.home-video-slider');

    if(!$s.length) return;

    Swiper.use([Navigation, EffectFade]);

    const slider = new Swiper('.home-video-slider', {
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.home-video-slider-next',
            prevEl: '.home-video-slider-prev',
        }
    });


    slider.on('slideChange', ()=> {
        $s.find('.cover-wrapper').show();
        $s.find('.youtube-video').each(function () {
            $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
                $s.find('.rutube-video').each(function() {
                    $(this)[0].contentWindow.postMessage(JSON.stringify({
                        type: 'player:pause',
                        data: {},
                    }), '*');
                    $(this)[0].contentWindow.postMessage(JSON.stringify({
                        type: 'player:setCurrentTime',
                        data: {
                            time: 0
                        }
                    }), '*');
                })

        });
    });
}
